import React, { useState, useEffect } from 'react';
import { Modal } from 'src/components';
import ReactMarkdown from 'react-markdown';

export const DashboardCard: React.FC<any> = ({ app }: { app: any }) => {
  const [readMoreVisible, setReadMoreVisible] = useState(false);
  const [content, setContent] = useState('');

  const onReadMoreCloseClick = () => setReadMoreVisible(false);

  useEffect(() => {
    fetch(app.markdownSrc)
      .then((res) => res.text())
      .then((md) => {
        return setContent(md);
      })
      .catch(() => {});
  }, [app.markdownSrc]);

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-100 mb-4 md:mb-0" key={app.name}>
        <div className="px-4 py-5 sm:p-6">
          <div className="mr-4 flex items-center">
            <img
              className="h-16 w-16 rounded-md overflow-hidden mr-4 flex-shrink-0"
              src={app.assetSrc}
              alt="Nextcloud"
            />

            <div>
              <h2 className="text-xl leading-8 font-bold">{app.name}</h2>
            </div>
          </div>
        </div>
        <div className="px-2.5 py-2.5 sm:px-4 flex justify-end">
          <a
            href={app.url}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Launch App
          </a>
        </div>
      </div>

      <Modal open={readMoreVisible} onClose={onReadMoreCloseClick} title={app.name}>
        <ReactMarkdown className="prose">{content}</ReactMarkdown>
      </Modal>
    </>
  );
};
