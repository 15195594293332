import { initialAppRoles } from 'src/components/UserModal/consts';

export type MultipleUsersModalProps = {
  open: boolean;
  onClose: () => void;
};

export const initialMultipleUsersForm = {
  appRoles: initialAppRoles,
};
