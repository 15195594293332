import { UserActionTypes } from './actions';

const initialUsersState: any = {
  users: [],
  user: {},
  userModalLoading: false,
  usersLoading: false,
};

const usersReducer = (state: any = initialUsersState, action: any) => {
  switch (action.type) {
    case UserActionTypes.FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case UserActionTypes.SET_USER_MODAL_LOADING:
      return {
        ...state,
        userModalLoading: action.payload,
      };
    case UserActionTypes.SET_USERS_LOADING:
      return {
        ...state,
        usersLoading: action.payload,
      };
    case UserActionTypes.FETCH_USER:
    case UserActionTypes.UPDATE_USER:
    case UserActionTypes.CREATE_USER:
    case UserActionTypes.CREATE_BATCH_USERS:
      return {
        ...state,
        isModalVisible: false,
        user: action.payload,
      };
    case UserActionTypes.DELETE_USER:
      return {
        ...state,
        user: {},
      };
    default:
      return state;
  }
};

export default usersReducer;
