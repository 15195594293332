import { useDispatch, useSelector } from 'react-redux';
import {
  getUsers,
  fetchUsers,
  fetchUserById,
  fetchPersonalInfo,
  updateUserById,
  updatePersonalInfo,
  createUser,
  deleteUser,
  clearCurrentUser,
  createBatchUsers,
} from '../redux';
import { getUserById, getUserModalLoading, getUserslLoading } from '../redux/selectors';

export function useUsers() {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const user = useSelector(getUserById);
  const userModalLoading = useSelector(getUserModalLoading);
  const userTableLoading = useSelector(getUserslLoading);

  function loadUsers() {
    return dispatch(fetchUsers());
  }

  function loadUser(id: string) {
    return dispatch(fetchUserById(id));
  }

  function loadPersonalInfo() {
    return dispatch(fetchPersonalInfo());
  }

  function clearSelectedUser() {
    return dispatch(clearCurrentUser());
  }

  function editUserById(data: any) {
    return dispatch(updateUserById(data));
  }

  function editPersonalInfo(data: any) {
    return dispatch(updatePersonalInfo(data));
  }

  function createNewUser(data: any) {
    return dispatch(createUser(data));
  }

  function createUsers(data: any) {
    return dispatch(createBatchUsers(data));
  }

  function deleteUserById(id: string) {
    return dispatch(deleteUser(id));
  }

  return {
    users,
    user,
    loadUser,
    loadUsers,
    loadPersonalInfo,
    editUserById,
    editPersonalInfo,
    userModalLoading,
    userTableLoading,
    createNewUser,
    deleteUserById,
    clearSelectedUser,
    createUsers,
  };
}
