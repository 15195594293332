export interface User {
  id: string;
  app_roles: AppRoles[];
  email: string;
  name: string;
  preferredUsername: string;
  status: string;
}

export interface FormUser extends User {
  password?: string;
  confirmPassword?: string;
}

export enum UserRole {
  NoAccess = 'no_access',
  Admin = 'admin',
  User = 'user',
}

export interface AppRoles {
  name: string | null;
  role: UserRole | null;
}

export interface UserApiRequest {
  id: number | null;
  email: string;
  name: string;
  status: string;
}

export interface MultipleUsersData {
  csvUserData: string;
  appRoles: AppRoles[];
}
