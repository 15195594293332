import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToast, ToastType } from 'src/common/util/show-toast';
import { useAuth } from 'src/services/auth';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function LoginCallback() {
  const currentURL = window.location.href;
  const indexOfQuestionMark = currentURL.indexOf('?');
  const params = currentURL.slice(indexOfQuestionMark);

  const navigate = useNavigate();

  const { logIn } = useAuth();

  useEffect(() => {
    async function logInUser() {
      if (params.length > 2) {
        const res = await logIn(params);

        // @ts-ignore
        if (!res.ok) {
          navigate('/login');
          showToast('Something went wrong, please try logging in again.', ToastType.Error);
        }
      }
    }

    logInUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center border border-transparent text-base font-medium rounded-md text-white transition ease-in-out duration-150">
            <svg
              className="animate-spin h-10 w-10 text-primary"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path
                className="opacity-100"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>
          <p className="text-lg text-primary-600 mt-2">Logging You in, just a moment.</p>
        </div>
      </div>
    </div>
  );
}
