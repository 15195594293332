import { ChartBarIcon, InformationCircleIcon } from '@heroicons/react/outline';

export const DASHBOARD_APPS = (rootDomain: string) => [
  {
    id: 1,
    name: 'Nextcloud',
    assetSrc: '/assets/nextcloud.svg',
    markdownSrc: '/markdown/nextcloud.md',
    url: `https://files.${rootDomain}`,
  },
  {
    id: 2,
    name: 'Wekan',
    assetSrc: '/assets/wekan.svg',
    markdownSrc: '/markdown/wekan.md',
    url: `https://wekan.${rootDomain}`,
  },
  {
    id: 3,
    name: 'Zulip',
    assetSrc: '/assets/zulip.svg',
    markdownSrc: '/markdown/zulip.md',
    url: `https://zulip.${rootDomain}`,
  },
  {
    id: 4,
    name: 'Wordpress',
    assetSrc: '/assets/wordpress.svg',
    markdownSrc: '/markdown/wordpress.md',
    url: `https://www.${rootDomain}`,
  },
];

export const DASHBOARD_QUICK_ACCESS = (rootDomain: string) => [
  {
    id: 1,
    name: 'Monitoring →',
    url: `https://grafana.${rootDomain}`,
    description: 'Monitor your system with Grafana',
    icon: ChartBarIcon,
    active: true,
  },
  {
    id: 2,
    name: 'Support →',
    url: 'https://docs.stackspin.net',
    description: 'Access documentation and forum',
    icon: InformationCircleIcon,
    active: true,
  },
];
