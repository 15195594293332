import React from 'react';
import clsx from 'clsx';
import { LockClosedIcon } from '@heroicons/react/solid';

import { performApiCall } from 'src/services/api';
import { showToast, ToastType } from 'src/common/util/show-toast';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Login() {
  const handleSubmit = async () => {
    try {
      const { data } = await performApiCall({
        path: '/login',
        method: 'POST',
      });

      if (data.authorizationUrl) {
        window.location.href = data.authorizationUrl;
      }
    } catch (e: any) {
      showToast('Something went wrong', ToastType.Error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex justify-center">
          <img className="lg:block" src="assets/logo.svg" alt="Stackspin" />
          <h2 className="mt-6 text-center text-xl font-bold text-gray-900 sr-only">Sign in</h2>
        </div>
        <button
          onClick={handleSubmit}
          type="button"
          className={clsx(
            'group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-dark hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
          )}
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon className="h-5 w-5 text-white group-hover:text-primary-light" aria-hidden="true" />
          </span>
          Sign in
        </button>
      </div>
    </div>
  );
}
