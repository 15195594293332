/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { SearchIcon, PlusIcon, ViewGridAddIcon } from '@heroicons/react/solid';
import { CogIcon, TrashIcon } from '@heroicons/react/outline';
import { useUsers } from 'src/services/users';
import { Table } from 'src/components';
import { debounce } from 'lodash';
import { useAuth } from 'src/services/auth';

import { UserModal } from '../../components/UserModal';
import { MultipleUsersModal } from './components';

export const Users: React.FC = () => {
  const [selectedRowsIds, setSelectedRowsIds] = useState({});
  const [configureModal, setConfigureModal] = useState(false);
  const [multipleUsersModal, setMultipleUsersModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [search, setSearch] = useState('');
  const { users, loadUsers, userTableLoading } = useUsers();
  const { isAdmin } = useAuth();

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 250), []);

  useEffect(() => {
    loadUsers();

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const filterSearch = useMemo(() => {
    return users.filter((item: any) => item.email?.toLowerCase().includes(search.toLowerCase()));
  }, [search, users]);

  const configureModalOpen = (id: any) => {
    setUserId(id);
    setConfigureModal(true);
  };

  const configureModalClose = () => setConfigureModal(false);

  const multipleUsersModalClose = () => setMultipleUsersModal(false);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 'auto',
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 'auto',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 'auto',
      },
      {
        Header: ' ',
        Cell: (props: any) => {
          const { row } = props;

          if (isAdmin) {
            return (
              <div className="text-right lg:opacity-0 group-hover:opacity-100 transition-opacity">
                <button
                  onClick={() => configureModalOpen(row.original.id)}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  <CogIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Configure
                </button>
              </div>
            );
          }

          return null;
        },
        width: 'auto',
      },
    ],
    [isAdmin],
  );

  const selectedRows = useCallback((rows: Record<string, boolean>) => {
    setSelectedRowsIds(rows);
  }, []);

  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 h-full flex-grow">
        <div className="pb-5 mt-6 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h1 className="text-3xl leading-6 font-bold text-gray-900">Users</h1>

          {isAdmin && (
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <button
                onClick={() => configureModalOpen(null)}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-800 mx-5 "
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add new user
              </button>
              <button
                onClick={() => setMultipleUsersModal(true)}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-800"
              >
                <ViewGridAddIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add new users
              </button>
            </div>
          )}
        </div>

        <div className="flex justify-between w-100 my-3 items-center mb-5 ">
          <div className="flex items-center">
            <div className="inline-block">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 sr-only">
                Search candidates
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-md pl-10 sm:text-sm border-gray-200"
                    placeholder="Search Users"
                    onChange={debouncedSearch}
                  />
                </div>
              </div>
            </div>
          </div>

          {selectedRowsIds && Object.keys(selectedRowsIds).length !== 0 && (
            <div className="flex items-center">
              <button
                onClick={() => {}}
                type="button"
                className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <TrashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Delete
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-hidden">
                <Table
                  data={filterSearch as any}
                  columns={columns}
                  getSelectedRowIds={selectedRows}
                  loading={userTableLoading}
                />
              </div>
            </div>
          </div>
        </div>

        {configureModal && (
          <UserModal open={configureModal} onClose={configureModalClose} userId={userId} setUserId={setUserId} />
        )}
        {multipleUsersModal && <MultipleUsersModal open={multipleUsersModal} onClose={multipleUsersModalClose} />}
      </div>
    </div>
  );
};
