import { UserRole } from 'src/services/users';

export const appAccessList = [
  {
    name: 'wekan',
    image: '/assets/wekan.svg',
    label: 'Wekan',
  },
  {
    name: 'wordpress',
    image: '/assets/wordpress.svg',
    label: 'Wordpress',
  },
  {
    name: 'nextcloud',
    image: '/assets/nextcloud.svg',
    label: 'Nextcloud',
  },
  {
    name: 'zulip',
    image: '/assets/zulip.svg',
    label: 'Zulip',
  },
];

export const allAppAccessList = [
  {
    name: 'dashboard',
    image: '/assets/logo-small.svg',
    label: 'Dashboard',
  },
  ...appAccessList,
];

export const initialAppRoles = [
  {
    name: 'dashboard',
    role: UserRole.User,
  },
  {
    name: 'wekan',
    role: UserRole.User,
  },
  {
    name: 'wordpress',
    role: UserRole.User,
  },
  {
    name: 'nextcloud',
    role: UserRole.User,
  },
  {
    name: 'zulip',
    role: UserRole.User,
  },
];

export const initialUserForm = {
  id: '',
  name: '',
  email: '',
  app_roles: initialAppRoles,
  status: '',
};
