import React from 'react';
import { useController } from 'react-hook-form';

/* eslint-disable react/react-in-jsx-scope */
export const Input = ({ control, name, type = 'text', label, required, ...props }: InputProps) => {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: '',
  });

  return (
    <>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <input
        type={type}
        id={name}
        onChange={field.onChange} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        value={field.value ? field.value.toString() : ''} // input value
        name={name} // send down the input name
        ref={field.ref} // send input ref, so we can focus on input when error appear
        autoComplete="given-name"
        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
        {...props}
      />
    </>
  );
};

type InputProps = {
  control: any;
  name: string;
  type?: string;
  label?: string;
} & React.HTMLProps<HTMLInputElement>;
