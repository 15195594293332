import React from 'react';
import clsx from 'clsx';
import { DASHBOARD_APPS, DASHBOARD_QUICK_ACCESS } from './consts';
import { DashboardCard } from './components';

export const Dashboard: React.FC = () => {
  const host = window.location.hostname;
  const splitedDomain = host.split('.');
  splitedDomain.shift();
  const rootDomain = splitedDomain.join('.');

  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8">
        <div className="mt-6 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h1 className="text-3xl leading-6 font-bold text-gray-900">Dashboard</h1>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 h-full flex-grow">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-4 mb-10">
          {DASHBOARD_APPS(rootDomain!).map((app) => (
            <DashboardCard app={app} key={app.name} />
          ))}
        </div>

        <div className="max-w-4xl mx-auto py-4 sm:px-6 lg:px-8 h-full flex-grow">
          <div className="pb-4 border-b border-gray-200 sm:flex sm:items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Utilities</h3>
          </div>

          <dl className="mt-5 grid grid-cols-1 gap-2 sm:grid-cols-2">
            {DASHBOARD_QUICK_ACCESS(rootDomain!).map((item) => (
              <a
                href={item.url}
                key={item.name}
                target="_blank"
                rel="noreferrer"
                className={clsx('bg-white rounded-lg overflow-hidden sm:p-2 flex items-center group', {
                  'opacity-40 cursor-default': !item.active,
                })}
              >
                <div className="w-16 h-16 flex items-center justify-center bg-primary-100 group-hover:bg-primary-200 transition-colors rounded-lg mr-4">
                  <item.icon className="h-6 w-6 text-primary-900" aria-hidden="true" />
                </div>
                <div>
                  <dt className="truncate text-sm leading-5 font-medium">{item.name}</dt>
                  <dd className="mt-1 text-gray-500 text-sm leading-5 font-normal">{item.description}</dd>
                </div>
              </a>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
